.complex-gradient-border {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.complex-gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.25px;
  border-radius: inherit;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
    linear-gradient(45deg, rgba(255, 255, 255, 0) 33.33%, rgba(255, 255, 255, 0.4) 95%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.complex-gradient-border > .content {
  position: relative;
  z-index: 1;
}

.complex-gradient-bg {
  background: linear-gradient(
    45deg,
    #ffffff 0%,
    #b2fbee 37%,
    #80f9e3 67%,
    #d6e1ea 88%,
    #ffffff 100%
  );
}

.blur {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.srlabs-card {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2);
}
